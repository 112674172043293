import * as React from "react";
import { Container } from 'react-bootstrap';

const WorkDetailVideo = ({ children }) => {
  return (
    <Container fluid className="workGallery">
      {children}
    </Container>
  );
};

export default WorkDetailVideo;
